//
// dropdown.scss
// Extended from Bootstrap
//

.dropdown {
  --#{$prefix}dropdown-min-width-xs: #{$dropdown-min-width-xs};
  --#{$prefix}dropdown-min-width-md: #{$dropdown-min-width-md};
  --#{$prefix}dropdown-min-width-lg: #{$dropdown-min-width-lg};
  --#{$prefix}dropdown-min-width-xl: #{$dropdown-min-width-xl};
  --#{prefix}dropdown-padding-y-xs: #{$dropdown-padding-y-xs};
  --#{prefix}dropdown-padding-x-xs: #{$dropdown-padding-x-xs};
  --#{$prefix}dropdown-padding-y-lg: #{$dropdown-padding-y-lg};
  --#{$prefix}dropdown-padding-x-lg: #{$dropdown-padding-x-lg};
  --#{$prefix}dropdown-item-padding-x-lg: #{$dropdown-item-padding-x-lg};
}

.dropdown-menu {
  padding-left: var(--#{$prefix}dropdown-padding-x);
  padding-right: var(--#{$prefix}dropdown-padding-x);
}


// Item

.dropdown-item {
  width: auto;
  min-width: 100%;
  margin-left: calc(#{$dropdown-padding-x} * -1);
  margin-right: calc(#{$dropdown-padding-x} * -1);

  &:hover, &:focus {
    outline: none;
  }
}

.dropdown-menu > li + li,
.dropdown-item + .dropdown-item {
  margin-top: $dropdown-item-spacing-y;
}


// Link (optional)

.dropdown-link {
  color: inherit;

  &:hover {
    text-decoration: inherit;
  }

  &:hover, &:focus {
    outline: none;
  }
}


// Header

.dropdown-header {
  margin-left: calc(#{$dropdown-padding-x} * -1);
  margin-right: calc(#{$dropdown-padding-x} * -1);
  padding-top: 0;
  padding-bottom: $headings-margin-bottom;
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  line-height: $h6-line-height;
  text-transform: uppercase;
  letter-spacing: .08em;
}


// Toggle

.dropdown-toggle::after {
  font-family: "Feather";
  vertical-align: middle;
  border: none !important;
  content: "\e92e";
}

.dropend > .dropdown-toggle::after {
  content: "\e930";
}


// Sizing

.dropdown-menu-xs {
  min-width: var(--#{$prefix}dropdown-min-width-xs);
  padding: var(--#{prefix}dropdown-padding-y-xs) var(--#{$prefix}dropdown-padding-x-xs);
}

.dropdown-menu-md {
  min-width: var(--#{$prefix}dropdown-min-width-md);
}

.dropdown-menu-lg {
  min-width: var(--#{$prefix}dropdown-min-width-lg);
}

.dropdown-menu-xl {
  min-width: var(--#{$prefix}dropdown-min-width-xl);
}

.dropdown-menu-lg,
.dropdown-menu-xl {
  padding: var(--#{$prefix}dropdown-padding-y-lg) var(--#{$prefix}dropdown-padding-x-lg);
}

.dropdown-menu-lg .dropdown-item,
.dropdown-menu-xl .dropdown-header {
  margin-left: calc(var(--#{$prefix}dropdown-padding-x-lg) * -1);
  margin-right: calc(var(--#{$prefix}dropdown-padding-x-lg) * -1);
  padding-left: var(--#{$prefix}dropdown-item-padding-x-lg);
  padding-right: var(--#{$prefix}dropdown-item-padding-x-lg);
}


// Positioning

.dropend > .dropdown-menu {
  top: calc(#{$dropdown-padding-y} * -1);
}

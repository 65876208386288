//
// badge.scss
// Extended from Bootstrap
//

.badge {
  --#{$prefix}badge-padding-y-lg: #{$badge-padding-y-lg};
  --#{$prefix}badge-padding-x-lg: #{$badge-padding-x-lg};
}

// Links

.badge[href] {

  &:hover {
    text-decoration: none;
  }
}


// Sizing

.badge-lg {
  padding: var(--#{$prefix}badge-padding-y-lg) var(--#{$prefix}badge-padding-x-lg);
}


// Rounded circle

.badge-rounded-circle {
  height: calc(1em + #{var(--#{$prefix}badge-padding-y)} * 2);
  padding-left: var(--#{$prefix}badge-padding-y);
  padding-right: var(--#{$prefix}badge-padding-y);
  border-radius: var(--#{$prefix}border-radius-pill);

  > * {
    display: block;
    width: 1em;
  }


  &.badge-lg {
    height: calc(1em + #{var(--#{$prefix}badge-padding-y-lg)} * 2);
    padding-left: var(--#{$prefix}badge-padding-y-lg);
    padding-right: var(--#{$prefix}badge-padding-y-lg);
  }
}


// Positioning

.badge-float {
  position: absolute;
  z-index: $zindex-dropdown;
}

.badge-float-inside {
  top: 1rem;
  right: 1rem;
}

.badge-float-outside {
  top: -.5rem;
  right: -.5rem;
}


// Active states

.badge.bg-white-soft.active {
  background-color: var(--#{$prefix}white) !important;
  color: var(--#{$prefix}primary) !important;

  &:hover, &:focus {
    background-color: darken($white, 5%);
    color: var(--#{$prefix}primary);
  }
}

.badge.bg-secondary-soft.active {
  background-color: var(--#{$prefix}primary) !important;
  color: color-contrast($primary) !important;

  &:hover, &:focus {
    background-color: darken($primary, 5%);
    color: color-contrast(darken($primary, 5%));
  }
}


// Creates the "soft" badge variant

@each $color, $value in $theme-colors {
  .badge.bg-#{$color}-soft {
    @include badge-variant-soft($value, $badge-soft-bg-opacity);
  }
}

.badge.bg-gray-700-soft {
  @include badge-variant-soft($gray-700, $badge-soft-bg-opacity);
}


// Color options

.badge.bg-gray-600 {
  background-color: var(--#{$prefix}gray-600);
  color: var(--#{$prefix}white);
}


// Text color
//
// Replacing the default white text color

@each $color, $value in $theme-colors {
  .badge.bg-#{$color} {
    color: color-contrast($value);
  }
}

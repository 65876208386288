// 
// user.scss
// Use this to write your custom SCSS
//

/*----------  Jobs & Stelleninserat  ----------*/

.jobs-info {
  margin-top: $padding-small;

  > span {
    display: inline-block;
  }
}

.options-extended {
  // margin-top: $padding-small;
  // display: none;

  .select2-container {
    margin-top: $padding-small;
  }
}

#jobs_total {
  font-size: 18px;
}

.job-cell {
  padding: $padding-small 0;

  &:not(:last-child) {
    border-bottom: solid 1px $grey;
  }
}

// Remove blue Link colour
.reset-link {
  color: $dark-grey;

  &:focus,
  &:hover {
    color: black;
    text-decoration: none;
  }
}

@media (max-width: $screen-sm-max) {
  .jobs-date, .jobs-pensum, .jobs-title {
    margin-top: $padding-small;
    margin-bottom: $padding-tiny;
  }
}

#jobs.float-box {
  padding-top: 0;
  margin-top: $padding-large;
}

.select-container {
  margin-top: 10px;
  overflow: hidden;
}

select {
  width: 100%;
}

.options-extended {
  .select2-container .select2-selection--single {
    height: 46px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 46px;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 46px;
    font-size: 18px;
  }
}

.stelleninserat-box {
  padding-bottom: 0;

  // Text that is not wrapped in <p>
  // Quick & Dirty
  font-size: 18px;
  font-weight: 100;
}

.stelleninserat-team {
  padding: 0;
  margin-bottom: 0;

  img {
    background-color: $light-grey;
  }
}

@media (min-width: $screen-sm) {
  .stelleninsrat-share-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .stelleninserat-apply {
      margin-bottom: 180px;
    }

    .stelleninserat-share {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.custon-icon {
  width: 10%;
}

.description_short {
  font-size: $font-size-sm;
}

.error-red {
  color: $danger;
}

.card-testimonial {
  color: $poolside;
  border-top-color: $poolside;
  min-height: 100%;
}

@media (min-width: 992px) {
  .modal-responsive {
    max-width: 800px; /* Equivalent to modal-lg */
  }
}

.flickity-cell {
  min-height: 100%;
}

.carousel-cell {
  min-height: 100%;
}
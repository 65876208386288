// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$poolside         : #4991c1; // #2980B9
$dark-grey        : #444;
$grey             : lighten($dark-grey, 50);// #CBCBCB;
$light-grey       : lighten($dark-grey, 70); // #F7F7F7;
$dark-blue        : #003853;

$padding-tiny     : 5px;
$padding-small    : 10px;
$padding-medium   : 15px;
$padding-large    : 20px;
$padding-huge     : 40px;
$padding-collosal : 60px;
$padding-enormous : 80px;

$screen-sm       : 768px;
$screen-sm-max   : $screen-sm - 1;